@import "../../variables";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/breakpoints";

.image-component {
    margin: 0;
    width: 100%;
    position: relative;
}

.common-image-component {
    width: 100%;
    object-fit: cover;
    object-position: var(--focal-point-x) var(--focal-point-y);
}

.mainbanner-image {
    height: 43.75vw;
    @include media-breakpoint-down(xs) {
        height: 75vw;
    }
}

.common-image-filter {
    filter: brightness(40%);
}

.image-heading-container {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
}

.image-heading-text {
    font-family: $image-text-font;
    color: $white-text;
}

.image-heading-container .mainbanner-sub-text p {
    font-size: $banner-sub-text-font-size;
    font-family: $banner-font;
    color: $banner-sub-text-color;
    text-decoration: underline;
}

.itc-image-heading-text {
    text-align: center;

    h1, h2, h3, h4, p, span {
        color: var(--text-overlay-color);
    }

    a {
        color: var(--text-overlay-link-color);
    }
}

.itc-text-underneath {
    text-align: center;

    h1, h2, h3, h4, p {
        color: var(--text-below-image-color);
    }
}
